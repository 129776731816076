<script setup lang="ts"></script>

<template>
    <svg
        width="19"
        height="19"
        viewBox="0 0 19 19"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g clip-path="url(#clip0_3201_1663)">
            <path
                d="M16.376 4.84312H9.4621L11.7349 1.35079C11.8296 1.20538 11.7884 1.01079 11.643 0.916137C11.4976 0.821481 11.303 0.862653 11.2083 1.0081L8.89639 4.56048L6.58449 1.0081C6.48987 0.862689 6.29524 0.821445 6.14983 0.916137C6.00442 1.01079 5.96321 1.20538 6.05787 1.35079L8.33068 4.84312H2.16452C1.0157 4.84312 0.0810547 5.77772 0.0810547 6.92658V15.8086C0.0810547 16.9574 1.0157 17.8921 2.16452 17.8921H16.376C17.5248 17.8921 18.4595 16.9575 18.4595 15.8086V6.92658C18.4594 5.77772 17.5248 4.84312 16.376 4.84312ZM16.0322 8.88395H17.3175C17.491 8.88395 17.6317 9.02463 17.6317 9.19811C17.6317 9.37159 17.491 9.51226 17.3175 9.51226H16.0322C15.8586 9.51226 15.718 9.37159 15.718 9.19811C15.718 9.02459 15.8586 8.88395 16.0322 8.88395ZM16.0322 10.346H17.3175C17.491 10.346 17.6317 10.4866 17.6317 10.6601C17.6317 10.8336 17.491 10.9743 17.3175 10.9743H16.0322C15.8586 10.9743 15.718 10.8336 15.718 10.6601C15.718 10.4866 15.8586 10.346 16.0322 10.346ZM15.077 15.381C15.077 16.2039 14.4075 16.8735 13.5845 16.8735H2.59785C1.77488 16.8735 1.10533 16.2039 1.10533 15.381V7.44857C1.10533 6.6256 1.77488 5.95605 2.59785 5.95605H13.5845C14.4075 5.95605 15.077 6.6256 15.077 7.44857V15.381ZM16.6513 14.4443C16.073 14.4443 15.6026 13.9738 15.6026 13.3956C15.6026 12.8174 16.073 12.3469 16.6513 12.3469C17.2295 12.3469 17.6999 12.8174 17.6999 13.3956C17.6999 13.9738 17.2295 14.4443 16.6513 14.4443ZM16.6513 12.9753C16.4195 12.9753 16.2309 13.1639 16.2309 13.3956C16.2309 13.6274 16.4195 13.8159 16.6513 13.8159C16.8831 13.8159 17.0716 13.6274 17.0716 13.3956C17.0716 13.1639 16.883 12.9753 16.6513 12.9753ZM13.5845 6.58439H2.59785C2.12134 6.58439 1.73367 6.97206 1.73367 7.44861V15.381C1.73367 15.8575 2.12134 16.2452 2.59785 16.2452H13.5845C14.061 16.2452 14.4487 15.8575 14.4487 15.381V7.44857C14.4487 6.97206 14.061 6.58439 13.5845 6.58439Z"
                fill="currentColor"
            />
        </g>
        <defs>
            <clipPath id="clip0_3201_1663">
                <rect
                    width="18.3784"
                    height="18.3784"
                    fill="white"
                    transform="translate(0.0810547 0.189453)"
                />
            </clipPath>
        </defs>
    </svg>
</template>
